//Add strings
gdprCookieNoticeLocales.en = {
  description: 'Um Ihnen den bestmöglichen Service und eine funktionsfähige Webseite zu bieten, verwenden wir Cookies. Durch die Nutzung dieser Webseite erklären Sie sich mit der Verwendung von Cookies einverstanden. Detaillierte Informationen sowie die Möglichkeit den Einsatz von Cookies zu unterbinden erhalten Sie unter: Cookie Einstellungen.',
  settings: 'Cookie Einstellungen',
  accept: 'Cookies zulassen',
  statement: 'Weitere Informationen zu Cookies',
  save: 'Einstellungen speichern',
  always_on: 'Immer aktiv',
  cookie_essential_title: 'Technisch notwendige Cookies',
  cookie_essential_desc: 'Diese Cookies sind für die Bereitstellung von Angeboten, die über unsere Website verfügbar sind, und für die Funktion unserer Website notwendig. Ohne diese Cookies ist der problemlose Betrieb dieser Webseite leider nicht möglich.',
  cookie_performance_title: 'Performance cookies',
  cookie_performance_desc: 'Diese Cookies werden verwendet, um unserer Website persönlich auf Sie anzupassen. Beispielsweise können diese Cookies Ihre Spracheinstellungen oder Anmeldedaten zu speichern.',
  cookie_analytics_title: 'Analytics cookies',
  cookie_analytics_desc: 'Diese Cookies werden zum Sammeln von Informationen verwendet, um Verhalten der Nutzer unserer Website zu analysieren. Diese Cookies können beispielsweise analysieren, wie lange Sie auf der Website verweilen oder welche Seiten Sie besuchen. So können wir unsere Website für Sie verbessern können. Die durch diese Cookies gesammelten Informationen identifizieren keinen einzelnen Besucher.',
  cookie_marketing_title: 'Marketing cookies',
  cookie_marketing_desc: 'Diese Cookies werden genutzt, um Werbung anzuzeigen, die für Sie aufgrund Ihrer Surfgewohnheiten wahrscheinlich von Interesse ist. Wenn Sie diese Cookies deaktivieren, werden Ihnen weiterhin Anzeigen angezeigt. Diese sind für Sie jedoch möglicherweise nicht von Interese.'
}
